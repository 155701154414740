import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  CallUs,
  ContactForm,
  Map,
  BannerLinks,
  TextBox
} from '../components'

import contacts from '../components/CallUs/DefaultContacts'

import BannerImage from '../assets/header.kontakt.jpg'
import JoinTheTeam from '../assets/bannerlinks/jointheteam.jpg'
import TakeOff from '../assets/bannerlinks/takeoff.jpg'
import MapImage from '../assets/map.jpg'

const boxes = [
  {
    text: 'Datenschutz',
    image: JoinTheTeam,
    link: '/datenschutzerklaerung',
    linkText: 'Mehr lesen'
  },
  {
    text: 'Impressum',
    image: TakeOff,
    link: '/impressum',
    linkText: 'Mehr lesen'
  }
]

const Kontakt = () => (
  <Layout>
    <Helmet
      title="Kontakt holzweg GmbH®"
      meta={[
        {
          name: 'description',
          content:
            'Sie wollen einen Schritt weiter Richtung digitale Fitness gehen? Kontaktieren Sie die holzweg GmbH® und vereinbaren Sie einen Termin mit uns! '
        },
        {
          name: 'keywords',
          content:
            'adresse holzweg, kontakt holzweg, telefon holzweg, telefonnummer holzweg, mail holzweg, mailadresse holzweg'
        },
        {
          property: 'og:description',
          content:
            'Sie wollen einen Schritt weiter Richtung digitale Fitness gehen? Kontaktieren Sie die holzweg GmbH® und vereinbaren Sie einen Termin mit uns! '
        },
        {
          property: 'og:image',
          content:
            'https://www.holzweg.com/static/header.kontakt-22a365b8b71ce5d94cf1f1f6e26bc535.jpg'
        }
      ]}
    />
    <BannerSmall image={BannerImage} title="Kontakt" />
    <TextBox
      title="Melden Sie sich doch!"
      text={
        <>
          <p>
            Suchen Sie eine Webagentur in Innsbruck zur Umsetzung Ihres
            digitalen Projektes? Egal ob es um die Entwicklung einer Webseite,
            eines Custom ERP-Systems oder einer kompletten
            Individualprogrammierung geht. Wir bei holzweg bieten Ihnen die
            passende Lösung für jedes Problem.
          </p>
          <p>
            Erzählen Sie uns von Ihren Projekten und Visionen und lassen Sie uns
            gemeinsam die perfekte Lösung für Ihr Unternehmen entwickeln.
            Kontaktieren Sie uns per E-Mail, Telefon oder vereinbaren Sie einen
            Termin für ein persönliches Treffen - wir freuen uns darauf, von
            Ihnen zu hören.
          </p>
        </>
      }
    />
    <CallUs contacts={contacts} title="Rufen Sie uns an!" />
    <ContactForm />
    <Map
      title="Anschrift"
      image={MapImage}
      text={
        <span>
          <h3>holzweg GmbH</h3>
          <p>
            Adamgasse 11, 3. Stock
            <br />
            6020 Innsbruck
            <br />
            Email:{' '}
            <a
              href="mailto:office@holzweg.com"
              target="_blank"
              className="link-effect"
            >
              office@holzweg.com
            </a>
            <br />
            Firmenbuchnummer: FN423141t
            <br />
            UID: ATU69192759
            <br />
            Steuernummer: 81/3258928
          </p>
        </span>
      }
    />
    <BannerLinks boxes={boxes} />
  </Layout>
)

export default Kontakt
